var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Designers selector-margin-section cursor-custom-logo"},[_c('v-container',{staticClass:"Designers__container-content px-8 px-sm-12 px-md-3 py-0 mb-12 pb-xl-12"},[_c('v-row',{staticClass:"mb-5"},[_c('v-col',{staticClass:"Project--line-height-texts",attrs:{"cols":"auto"}},[_c('span',{staticClass:"d-block titles aeonik-light"},[_vm._v("Architecture")]),_c('span',{staticClass:"d-block titles aeonik-light"},[_vm._v("& Interior")]),_c('span',{staticClass:"d-block titles aeonik-light"},[_vm._v("Design")])])],1),_c('v-row',{staticClass:"Designers__container-content__row animation",staticStyle:{"animation-delay":"2s"},attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 0)?[_c('div',{key:im + '-1',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                  asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                  'white--text':
                    asset.barColor == '#223151' ||
                    asset.barColor == '#1F6D5E' ||
                    asset.barColor == '#B2B2B2',
                },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 1)?[_c('div',{key:im + '-2',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 2)?[_c('div',{key:im + '-3',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 3)?[_c('div',{key:im + '-4',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 4)?[_c('div',{key:im + '-5',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 5)?[_c('div',{key:im + '-6',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 6)?[_c('div',{key:im + '-7',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 7)?[_c('div',{key:im + '-8',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 8)?[_c('div',{key:im + '-9',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                          asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                          'white--text':
                            asset.barColor == '#223151' ||
                            asset.barColor == '#1F6D5E' ||
                            asset.barColor == '#B2B2B2',
                        },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 9)?[_c('div',{key:im + '-10',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                          asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                          'white--text':
                            asset.barColor == '#223151' ||
                            asset.barColor == '#1F6D5E' ||
                            asset.barColor == '#B2B2B2',
                        },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"row-reverse mb-3 pb-md-4 pb-lg-12 pb-xl-12 animation-lazy",attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 10)?[_c('div',{key:im + '-11',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                  asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                  'white--text':
                    asset.barColor == '#223151' ||
                    asset.barColor == '#1F6D5E' ||
                    asset.barColor == '#B2B2B2',
                },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 11)?[_c('div',{key:im + '-12',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 12)?[_c('div',{key:im + '-13',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 13)?[_c('div',{key:im + '-14',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 14)?[_c('div',{key:im + '-15',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 15)?[_c('div',{key:im + '-16',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 16)?[_c('div',{key:im + '-17',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 17)?[_c('div',{key:im + '-18',staticClass:"bar-container"},[_c('v-img',{attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                      asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                      'white--text':
                        asset.barColor == '#223151' ||
                        asset.barColor == '#1F6D5E' ||
                        asset.barColor == '#B2B2B2',
                    },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 18)?[_c('div',{key:im + '-19',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                          asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                          'white--text':
                            asset.barColor == '#223151' ||
                            asset.barColor == '#1F6D5E' ||
                            asset.barColor == '#B2B2B2',
                        },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2),_c('v-col',[_vm._l((_vm.designersA),function(asset,im){return [(asset.image != null && im == 19)?[_c('div',{key:im + '-20',staticClass:"bar-container"},[_c('v-img',{staticClass:"Designers__container-content__row--square-image",attrs:{"src":'https://www.ofisdesigncms.actstudio.xyz/' +
                          asset.image.path}}),_c('div',{staticClass:"bar-content",class:{
                          'white--text':
                            asset.barColor == '#223151' ||
                            asset.barColor == '#1F6D5E' ||
                            asset.barColor == '#B2B2B2',
                        },style:('background-color: ' + asset.barColor + ';')},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","no-gutters":""}},[_c('v-col',{staticClass:"text-center"},[_c('span',[_vm._v(" "+_vm._s(asset.description)+" ")])])],1)],1)],1)]:_vm._e()]})],2)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"animation-lazy",attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"d-none d-sm-block"},[_c('v-img',{staticClass:"Designers__img-work",attrs:{"contain":"","src":require("@/assets/img/equipo-de-trabajo-ofis.jpg")}})],1),_c('div',{staticClass:"d-sm-none mb-6"},[_c('v-img',{attrs:{"height":"100%","src":require("@/assets/img/equipo-de-trabajo-ofis-mobile.jpg")}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }