<template>
  <div class="Designers selector-margin-section cursor-custom-logo">
    <v-container
      class="Designers__container-content px-8 px-sm-12 px-md-3 py-0 mb-12 pb-xl-12"
    >
      <v-row class="mb-5">
        <v-col cols="auto" class="Project--line-height-texts">
          <span class="d-block titles aeonik-light">Architecture</span>
          <span class="d-block titles aeonik-light">& Interior</span>
          <span class="d-block titles aeonik-light">Design</span>
        </v-col>
      </v-row>

      <!-- item 1 a 10 -->
      <v-row
        justify="center"
        no-gutters
        class="Designers__container-content__row animation"
        style="animation-delay: 2s"
      >
        <v-col cols="12" sm="6">
          <template v-for="(asset, im) in designersA">
            <template v-if="asset.image != null && im == 0">
              <div :key="im + '-1'" class="bar-container">
                <v-img
                  :src="
                    'https://www.ofisdesigncms.actstudio.xyz/' +
                    asset.image.path
                  "
                ></v-img>
                <div
                  class="bar-content"
                  v-bind:style="'background-color: ' + asset.barColor + ';'"
                  v-bind:class="{
                    'white--text':
                      asset.barColor == '#223151' ||
                      asset.barColor == '#1F6D5E' ||
                      asset.barColor == '#B2B2B2',
                  }"
                >
                  <v-row align="center" class="fill-height" no-gutters>
                    <v-col class="text-center">
                      <span>
                        {{ asset.description }}
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </template>
          </template>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 1">
                  <div :key="im + '-2'" class="bar-container">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 2">
                  <div class="bar-container" :key="im + '-3'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="12">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 3">
                  <div class="bar-container" :key="im + '-4'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 4">
                  <div class="bar-container" :key="im + '-5'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 5">
                  <div class="bar-container" :key="im + '-6'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="12">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 6">
                  <div class="bar-container" :key="im + '-7'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 7">
                  <div class="bar-container" :key="im + '-8'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col>
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 8">
                      <div class="bar-container" :key="im + '-9'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                          class="Designers__container-content__row--square-image"
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
                <v-col>
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 9">
                      <div class="bar-container" :key="im + '-10'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                          class="Designers__container-content__row--square-image"
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /item 1 a 10 -->

      <!-- item 11 a 20 -->
      <v-row
        justify="center"
        class="row-reverse mb-3 pb-md-4 pb-lg-12 pb-xl-12 animation-lazy"
        no-gutters
      >
        <v-col cols="12" sm="6">
          <template v-for="(asset, im) in designersA">
            <template v-if="asset.image != null && im == 10">
              <div class="bar-container" :key="im + '-11'">
                <v-img
                  :src="
                    'https://www.ofisdesigncms.actstudio.xyz/' +
                    asset.image.path
                  "
                ></v-img>
                <div
                  class="bar-content"
                  v-bind:style="'background-color: ' + asset.barColor + ';'"
                  v-bind:class="{
                    'white--text':
                      asset.barColor == '#223151' ||
                      asset.barColor == '#1F6D5E' ||
                      asset.barColor == '#B2B2B2',
                  }"
                >
                  <v-row align="center" class="fill-height" no-gutters>
                    <v-col class="text-center">
                      <span>
                        {{ asset.description }}
                      </span>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </template>
          </template>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 11">
                  <div class="bar-container" :key="im + '-12'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 12">
                  <div class="bar-container" :key="im + '-13'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="12">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 13">
                  <div class="bar-container" :key="im + '-14'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 14">
                  <div class="bar-container" :key="im + '-15'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 15">
                  <div class="bar-container" :key="im + '-16'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                      class="Designers__container-content__row--square-image"
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="12">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 16">
                  <div class="bar-container" :key="im + '-17'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" sm="6">
          <v-row no-gutters>
            <v-col cols="6">
              <template v-for="(asset, im) in designersA">
                <template v-if="asset.image != null && im == 17">
                  <div class="bar-container" :key="im + '-18'">
                    <v-img
                      :src="
                        'https://www.ofisdesigncms.actstudio.xyz/' +
                        asset.image.path
                      "
                    ></v-img>
                    <div
                      class="bar-content"
                      v-bind:style="'background-color: ' + asset.barColor + ';'"
                      v-bind:class="{
                        'white--text':
                          asset.barColor == '#223151' ||
                          asset.barColor == '#1F6D5E' ||
                          asset.barColor == '#B2B2B2',
                      }"
                    >
                      <v-row align="center" class="fill-height" no-gutters>
                        <v-col class="text-center">
                          <span>
                            {{ asset.description }}
                          </span>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </template>
              </template>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col>
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 18">
                      <div class="bar-container" :key="im + '-19'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                          class="Designers__container-content__row--square-image"
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
                <v-col>
                  <template v-for="(asset, im) in designersA">
                    <template v-if="asset.image != null && im == 19">
                      <div class="bar-container" :key="im + '-20'">
                        <v-img
                          :src="
                            'https://www.ofisdesigncms.actstudio.xyz/' +
                            asset.image.path
                          "
                          class="Designers__container-content__row--square-image"
                        ></v-img>
                        <div
                          class="bar-content"
                          v-bind:style="
                            'background-color: ' + asset.barColor + ';'
                          "
                          v-bind:class="{
                            'white--text':
                              asset.barColor == '#223151' ||
                              asset.barColor == '#1F6D5E' ||
                              asset.barColor == '#B2B2B2',
                          }"
                        >
                          <v-row align="center" class="fill-height" no-gutters>
                            <v-col class="text-center">
                              <span>
                                {{ asset.description }}
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </div>
                    </template>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- /item 11 a 20 -->

      <v-row no-gutters class="animation-lazy">
        <v-col>
          <!-- mobile -->
          <div class="d-none d-sm-block">
            <v-img
              class="Designers__img-work"
              contain
              src="@/assets/img/equipo-de-trabajo-ofis.jpg"
            ></v-img>
          </div>
          <!-- /mobile -->

          <!-- desk -->
          <div class="d-sm-none mb-6">
            <v-img
              height="100%"
              src="@/assets/img/equipo-de-trabajo-ofis-mobile.jpg"
            ></v-img>
          </div>
          <!-- /desk -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import VueSession from "vue-session";
Vue.use(VueSession);

export default {
  data() {
    return {
      designersA: [],
    };
  },
  beforeMount() {
    //fetch to designer´s collection
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/Designers?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          limit: 30,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.designersA = res.entries))
      .catch((error) => console.log(error));
  },
  mounted() {
    this.$emit("update", 1);
  },
  methods: {},
};
</script>